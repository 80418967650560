export const themeColors =
{
  darkColor:  "#101317",
  lightColor: "#f1f1f1",

  darkProjectCard: "#101317",
  lightProjectCard: "#f1f1f1",

  borderWidth: "3px",

  darkBGColor: "#1e1e1e",
  lightBGColor: "#FFFFFF"
}  